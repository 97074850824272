import { Form } from '../../scripts/form';

import './login.less';

(function() {
  const urlParams = new URLSearchParams(window.location.search);
  const paramRedirect = urlParams.get('redirect');

  let paramUsername;

  if (paramRedirect) {
    const redirectUrl = new URL(paramRedirect.startsWith('/') ? `https://a${paramRedirect}` : paramRedirect);
    paramUsername = redirectUrl.searchParams.get('username');
  }

  const loginBoxEl = document.getElementById('login-box');

  document.getElementById('google-login-form')
    .setAttribute('action', `${AUTH_SERVER_BASE_URL}/login/google`);

  document.getElementById('google-login-redirecturi')
    .setAttribute('value', `${MAIN_URL}/authCallback${paramRedirect !== null ? '?redirect=' + encodeURIComponent(paramRedirect) : ''}`);

  document.getElementById('pick-password').addEventListener('click', (e) => {
    e.preventDefault();
    loginBoxEl.classList.remove('pick-option');
    loginBoxEl.classList.add('option-password');
  });

  document.getElementById('pick-other-login-option').addEventListener('click', (e) => {
    e.preventDefault();
    loginBoxEl.classList.add('pick-option');
    loginBoxEl.classList.remove('option-password');
  });

  if (paramRedirect) {
    const decodedRedirect = decodeURIComponent(paramRedirect);

    if (decodedRedirect.includes('/app/activateInvite')) {
      document.getElementById('default-info').classList.add('hide');
      document.getElementById('invite-info').classList.remove('hide');
      document.getElementById('invite-email').innerText = paramUsername;
    }
  }

  initPasswordForm(paramRedirect, paramUsername);  
}());

function initPasswordForm(redirect, username) {
  const formEl = document.getElementById('login-password-form');
  const loginMsgEl = document.getElementById('password-login-msg');
  

  if (username) {
    formEl.querySelector('[name=username]').setAttribute('disabled', 'disabled');
    formEl.querySelector('[name=username]').value = username;
  }

  const form = new Form(formEl, () => {
    const { username, password } = form.values();
    const conf = {
      method: 'POST',
      body: `username=${username}&password=${password}`,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    }

    fetch(`${AUTH_SERVER_BASE_URL}/login/checkPassword`, conf)
      .then(resp => {
        if (resp.status !== 200) {
          return Promise.reject('Failed to login');
        }
        return resp;
      })
      .then(resp => resp.json())
      .then((resp) => {
        const { success } = resp;

        if (!success) {
          return Promise.reject('Failed to login');
        }
      })
      .then(() => {
        const tempForm = document.createElement('form');
        tempForm.setAttribute('method', 'post');
        tempForm.setAttribute('action', `${AUTH_SERVER_BASE_URL}/login/password`);

        const usernameField = document.createElement('input');
        usernameField.setAttribute('type', 'hidden');
        usernameField.setAttribute('name', 'username');
        usernameField.value = username;

        const passwordField = document.createElement('input');
        passwordField.setAttribute('type', 'password');
        passwordField.setAttribute('name', 'password');
        passwordField.value = password;

        const redirectField = document.createElement('input');
        redirectField.setAttribute('type', 'hidden');
        redirectField.setAttribute('name', 'redirectUri');

        redirectField.value = `${MAIN_URL}/authCallback${redirect !== null ? '?redirect=' + encodeURIComponent(redirect) : ''}`;

        tempForm.appendChild(usernameField);
        tempForm.appendChild(passwordField);
        tempForm.appendChild(redirectField);

        document.body.appendChild(tempForm);
        tempForm.submit();
      })
      .catch((error) => {
        loginMsgEl.innerHTML = error;
      });
  });
}