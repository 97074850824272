import { validate, validatorNames } from './validators';

const errorMessages = {
  required: 'This field is required',
  email: 'Please enter a valid email'
};

export const Form = function($formEl, onSubmit) {
  this.$formEl = $formEl;
  const ignoreValidationMessagesAttr = $formEl.getAttribute('ignore-validation-messages');
  const ignoreValidationMessages = ignoreValidationMessagesAttr !== null
    && typeof ignoreValidationMessagesAttr !== 'undefined'

  const inputElements = [];
  const validationElements = [];
  const wrapElements = [];

  $formEl.querySelectorAll('input, select').forEach((el) => {
    inputElements.push(el);
    const name = el.getAttribute('name');

    if (name) {
      const validationEl = $formEl.querySelectorAll(`.validation-error[for=${name}]`);
      const wrapEl = $formEl.querySelectorAll(`.form-control[for=${name}], .form-control-icon[for=${name}]`);

      if (!ignoreValidationMessages && validationEl.length > 0) {
        validationElements.push({
          for: name,
          el: validationEl[0]
        });
      }

      if (wrapEl.length > 0){
        wrapElements.push({
          for: name,
          el: wrapEl[0]
        });
      }
    }
  });

  const init = () => {
    $formEl.setAttribute('novalidate', '');

    inputElements.forEach(($input) => {
      const name = $input.getAttribute('name');
      setValidity($input);

      $input.addEventListener('input', () => {
        setValidity($input);
      });

      $input.addEventListener('focus', () => {
        const wrap = name && wrapElements.find(e => e.for === name);
        if (wrap) {
          wrap.el.classList.add('focus');
        }
      });

      $input.addEventListener('blur', () => {
        $input.classList.add('touched');

        const wrap = name && wrapElements.find(e => e.for === name);
        if (wrap) {
          wrap.el.classList.add('touched');
          wrap.el.classList.remove('focus');
        }
      });
    });

    $formEl.addEventListener('submit', (event) => {
      event.preventDefault();
      $formEl.classList.add('submitted');

      if (!this.valid()) {
        return;
      }

      const values = this.values();

      if (onSubmit) {
        onSubmit(values);
      }
    });
  };

  /**
   * Toggles classes based on validity, both on the input
   * field itself, and the wrapping element, if any.
   *
   * @param {object} $input DOM element
   */
  const setValidity = ($input) => {
    const name = $input.getAttribute('name');
    const { valid, errors } = validate($input);

    const wrap = name && wrapElements.find(e => e.for === name);

    if (!valid) {
      $input.classList.add('invalid');
      if (wrap) {
        wrap.el.classList.add('invalid');
      }
    } else {
      $input.classList.remove('invalid');
      if (wrap) {
        wrap.el.classList.remove('invalid');
      }
    }

    $input.classList.remove('invalid-required');

    if (wrap) {
      wrap.el.classList.remove('invalid-required');
    }

    for (let validatorName of validatorNames) {
      $input.classList.remove(`invalid-${validatorName}`);
      if (wrap) {
        wrap.el.classList.remove(`invalid-${validatorName}`);
      }
    }

    for (let error of errors) {
      $input.classList.add(`invalid-${error}`);
      if (wrap) {
        wrap.el.classList.add(`invalid-${error}`);
      }
    }

    // Update error message
    if (name) {
      const validationElement = validationElements.find(e => e.for === name);
      if (validationElement) {
        validationElement.el.innerHTML = errors.length > 0
          ? errorMessages[errors[0]] || 'Invalid'
          : '';
      }
    }
  }

  this.valid = () => {
    return inputElements.every(i => validate(i).valid);
  }

  /**
   * Returns all field values from the form.
   * Invalid fields will not be included.
   */
  this.values = () => {
    return inputElements.reduce((acc, $input) => {
      let value;
      const type = $input.getAttribute('type');

      if (type === 'checkbox') {
        value = $input.checked;
      } else {
        value = $input.value;
      }

      const name = $input.getAttribute('name');

      const { valid } = validate($input);

      if (valid) {
        acc[name] = value;
      }

      return acc;
    }, {});
  }

  init();
}
