export const validators = {
  'min-len': (str, val) => str.length >= Number(val),
  'max-len': (str, val) => str.length <= Number(val),
  'email': str => /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(str)
};

export const validatorNames = Object.keys(validators);

/**
 * Check if a DOM input field is valid.
 * If the field is not required and empty, it's considered valid.
 *
 * @param {object} $input DOM element
 */
export const validate = ($input) => {
  const required = typeof $input.getAttribute('required') !== 'undefined';
  const value = $input.value;

  let errors = [];

  if (required && _isNone(value)) {
    errors.push('required');
  }

  errors = validatorNames.reduce((acc, curr) => {
    const attrValue = $input.getAttribute(curr);
    if (!_isNullOrUndefined(attrValue) && !validators[curr](value, attrValue)) {
      acc.push(curr);
    }
    return acc;
  }, errors);

  return {
    valid: errors.length === 0,
    errors
  };
}

function _isNone(value) {
  return typeof value === 'undefined' || value === null || value === '';
}

function _isNullOrUndefined(value) {
  return value === null || typeof value === 'undefined';
}
